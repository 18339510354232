import React from "react";
import NavBar from "../../../../Navbar/Navbar";
import MainBodyContainer from "../../../../Common/Body/MainBodyContainer/MainBodyContainer";
import Footer from "../../../../Footer/Footer";
import { Link } from "react-router-dom";

import "./LondonCommunityOutreach.css";

const LondonCommunityOutreach = () => {
  return (
    <>
      <NavBar />
      <MainBodyContainer>
        <div className="content-container">
          <h1>Empowering Londoners through Skills and Employment Support</h1>
          <p>
            Join our Community Outreach Project, supported by the Mayor of
            London!
          </p>
          <p>
            At our Community Outreach Project, we are passionate about helping
            Londoners unlock their full potential. Our mission is to provide
            personalised career mentoring and essential employment support,
            making it easier for local residents to access learning
            opportunities and build sustainable careers.
          </p>
          <p>
            We understand that every person’s journey is different, which is why
            we tailor our support to your individual needs. Whether you’re
            looking to improve your skills, find suitable training courses, or
            boost your confidence in job applications, we are here to help.
          </p>

          <h2>What We Offer</h2>
          <ul>
            <li>
              <span className="highlight">Career Mentoring</span> - Receive
              one-on-one guidance from our experts who will help you explore
              career options, set realistic goals, and identify the steps you
              need to take to achieve them.
            </li>
            <li>
              <span className="highlight">
                Linking You to Adult Learning Opportunities
              </span>{" "}
              - We help you connect with suitable{" "}
              <span className="highlight">Multiply</span> and{" "}
              <span className="highlight">ESOL</span> courses and other adult
              learning programmes in your area. Whether you're looking to
              enhance your maths, English, or general skills, we ensure you are
              pointed in the right direction.
            </li>
            <li>
              <span className="highlight">Support with Job Applications</span> -
              Our team provides hands-on assistance with writing CVs, crafting
              cover letters, and preparing for job interviews. We aim to equip
              you with the tools to stand out in today’s competitive job market.
            </li>
            <li>
              <span className="highlight">Benefit Applications and Advice</span>{" "}
              - Navigating the benefits system can be confusing, but we’re here
              to simplify the process. We offer support and advice to help you
              access the financial support you're entitled to.
            </li>
            <li>
              <span className="highlight">
                Confidence Building and Self-Esteem Support
              </span>{" "}
              - We believe that everyone deserves to feel confident about their
              future. Our support is designed to build your self-esteem,
              ensuring that you are prepared to take the next step in your
              career with pride.
            </li>
          </ul>

          <h2>Why Join Us?</h2>
          <p>
            By engaging with our project, you’re not just signing up for
            training – you’re investing in a brighter future. Our goal is to
            equip you with the skills you need to not only become employable but
            to sustain long-term employment and contribute to the economic
            growth of London.
          </p>

          <h2>Supported by the Mayor of London</h2>
          <p>
            We’re proud to be part of an initiative backed by the Mayor of
            London, ensuring that local communities across London have access to
            the support, advice, and learning opportunities they deserve.
          </p>
          <p>
            Whether you need help finding the right course, improving your
            employability, or getting advice on benefits, we’re here for you.
            Together, we’ll make sure you are ready for the next chapter of your
            journey.
          </p>

          <h2>Get Started Today!</h2>
          <p>
            Reach out to us and discover how we can help you achieve your goals.
            Let’s build a stronger, more skilled community, one step at a time.
          </p>
          <Link to="/contact" className="body-button">
            <div className="body-btn-circle"></div>
            <a href="/">Contact Us</a>
          </Link>
        </div>
      </MainBodyContainer>
      <Footer />
    </>
  );
};

export default LondonCommunityOutreach;
