import React, { useState } from "react";

import "../Popup/Popup.css";

function Popup({ employee }) {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => setIsOpen(!isOpen);

  const text = employee.description;
  const sentences = text.split(". ");
  const sentenceChunks = [];
  let currentChunk = [];

  for (let i = 0; i < sentences.length; i++) {
    const sentence = sentences[i];
    currentChunk.push(sentence);
    if (i > 0 && (i + 1) % 4 === 0) {
      sentenceChunks.push(currentChunk);
      currentChunk = [];
    }
  }

  if (currentChunk.length > 0) {
    sentenceChunks.push(currentChunk);
  }

  const formattedText = sentenceChunks.map((chunk) => {
    const lastSentence = chunk[chunk.length - 1];
    const hasPeriod = lastSentence.endsWith(".");
    if (!hasPeriod) {
      chunk[chunk.length - 1] = lastSentence + ".";
    }
    return chunk.join(". ");
  });
  const paragraphs = formattedText.map((text, index) => (
    <p className="employee-details" key={index}>
      {text}
    </p>
  ));

  return (
    <>
      <div className="row-item" onClick={togglePopup}>
        <div className="image-container">
          <img
            src={require(`../../../images/employees/${employee.photo}`)}
            alt={employee.name + " " + employee.title + " image."}
          />
        </div>
        <div className="employee-info-large">
          <h2>{employee.name}</h2>
          <h3>{employee.title}</h3>
        </div>
      </div>

      {isOpen && (
        <div className="popup">
          <div className="popup-inner">
            <div className="popup-heading">
              <h2 className="employee-name">
                {employee.name}
                <span className="employee-title">{" | " + employee.title}</span>
              </h2>
            </div>

            <div className="popup-body">
              <div className="employee-image-container">
                <img
                  src={require(`../../../images/employees/${employee.photo}`)}
                  alt={employee.name}
                  className="employee-image"
                />
              </div>

              <div className="employee-info">
                <div className="employee-details-container">{paragraphs}</div>
              </div>
            </div>
            
          </div>
          <span className="close-btn" onClick={togglePopup}>
            {/* Just an SVG to hold the close button. */}
            <svg
              className="close-btn-svg"
              height="100"
              width="100"
              viewBox="0 0 100 100"
            >
              <path className="line" d="M 34,32 L 66,68" />
              <path className="line" d="M 66,32 L 34,68" />
            </svg>
          </span>
        </div>
      )}
    </>
  );
}

export default Popup;
