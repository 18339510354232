import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../Navbar/Navbar";
import Footer from "../../Footer/Footer";
import "./Privacy-Policy.css";

class PrivacyPolicy extends Component {

    render() {
        return (
          <div>
            <Navbar />
            <div>
              <main className="main-body">
                <h1 className="main-body-heading">Legal</h1>
                <div className="privacy-policy-container">
                  <div width="2" className="privacy-policy-content-section">
                    <div className="privacy-policy-container">
                      <section className="privacy-policy-heading">
                        <div
                          width="default"
                          className="privacy-policy-text-container"
                        >
                          <h2 className="privacy-policy-heading-text">
                            Our Privacy Policy <sup> (boooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooring.)</sup>
                          </h2>
                          <br />
                          <p className="timestamp-label">
                            Last Updated: 14 February, 2022
                          </p>
                          <hr />
                          <p className="legal-text">
                            We at Alisar Prospects Training (defined below)
                            respect and protect the privacy of visitors to our
                            websites and our customers. This Privacy Policy
                            describes our information handling practices when
                            you access our services, which include our content
                            on the websites located at{" "}
                            <Link to="/">alisarprospects.training</Link>,{" "}
                            <Link to="/cv">alisarprospects.training/cv</Link>,
                            or any other websites, pages, features, or content
                            we own or operate (collectively, the "Site(s)") or
                            when you use the Alisar Prospects Training Website,
                            and related services (referred to collectively
                            hereinafter as "Services").
                          </p>
                          <p className="legal-text">
                            Please take a moment to read this Privacy Policy
                            carefully. If you have any questions about this
                            Policy, please submit your request via Email at{" "}
                            <a href="mailto:info@alisarprospects.training">
                              info@alisarprospects.training
                            </a>
                            .
                          </p>
                          <p className="legal-text">
                            We may modify this Privacy Policy from time to time
                            which will be indicated by changing the date at the
                            top of this page. If we make any material changes,
                            we will notify you of any changes by posting the new
                            Privacy Policy on this page, or as otherwise
                            required by law. These changes are effective
                            immediately, after they are posted on this page.
                          </p>
                        </div>
                      </section>
                      <section
                        title="1. Acceptance of This Privacy Policy"
                        id="acceptance-of-this-privacy-policy"
                      >
                        <div
                          width="default"
                          className="privacy-policy-text-container"
                        >
                          <h3 className="privacy-policy-subheading-text">
                            1. Acceptance of This Privacy Policy
                          </h3>
                          <p className="legal-text">
                            By accessing and using our Services, you signify
                            acceptance to the terms of this Privacy Policy.
                            Where we require your consent to process your
                            personal information, we will ask for your consent
                            to the collection, use, and disclosure of your
                            personal information as described further below. We
                            may provide additional "just-in-time" disclosures or
                            information about the data processing practices of
                            specific Services. These notices may supplement or
                            clarify our privacy practices or may provide you
                            with additional choices about how we process your
                            data.
                          </p>
                          <p className="legal-text">
                            If you do not agree with or you are not comfortable
                            with any aspect of this Privacy Policy, you should
                            immediately discontinue access or use of our
                            Services.
                          </p>
                        </div>
                      </section>
                      <section
                        title="2. The Information We Collect"
                        id="the-information-we-collect"
                      >
                        <div
                          width="default"
                          className="privacy-policy-text-container"
                        >
                          <h3 className="privacy-policy-subheading-text">
                            2. The Information we collect
                          </h3>
                          <p className="legal-text">
                            For a better experience while using our Service, we
                            may require you to provide us with certain
                            personally identifiable information, including but
                            not limited to your name, phone number, and postal
                            address. The information that we collect will be
                            used to contact or identify you.
                          </p>
                          <p className="legal-text">
                            We want to inform you that whenever you visit our
                            Service, we collect information that your browser
                            sends to us that is called Log Data. This Log Data
                            may include information such as your computer’s
                            Internet Protocol ("IP") address, browser version,
                            pages of our Service that you visit, the time and
                            date of your visit, the time spent on those pages,
                            and other statistics.
                          </p>
                        </div>
                      </section>
                      <section 
                       title="3. Relationships with Service Providers"
                       id="relationships-with-service-providers"
                      >
                        <div
                          width="default"
                          className="privacy-policy-text-container"
                        >
                          <h3 className="privacy-policy-subheading-text">
                            3. Relationships with service providers
                          </h3>
                          <p className="legal-text">
                            We may employ third-party companies and individuals
                            due to the following reasons:
                          </p>
                          <ul className="legal-list">
                            <li>To facilitate our Service;</li>
                            <li>To provide the Service on our behalf;</li>
                            <li>To perform Service-related services; or</li>
                            <li>
                              To assist us in analyzing how our Service is used.
                            </li>
                          </ul>
                          <p className="legal-text">
                            We want to inform our Service users that these third
                            parties have access to your Personal Information.
                            The reason is to perform the tasks assigned to them
                            on our behalf. However, they are obligated not to
                            disclose or use the information for any other
                            purpose.
                          </p>
                        </div>
                      </section>
                      <section 
                       title="4. How We Protect And Store Your Data"
                       id="how-we-protect-and-store-your-data"
                      >
                        <div
                          width="default"
                          className="privacy-policy-text-container"
                        >
                          <h3 className="privacy-policy-subheading-text">
                            4. How we protect and store your data
                          </h3>
                          <p className="legal-text">
                            We understand how important your privacy is, which
                            is why APT maintains (and contractually requires
                            third parties it shares your information with to
                            maintain) appropriate physical, technical and
                            administrative safeguards to protect the security
                            and confidentiality of the personal information you
                            entrust to us.
                          </p>
                          <p className="legal-text">
                            For example, we use computer safeguards such as
                            firewalls and data encryption, we enforce physical
                            access controls to our buildings and files, and we
                            authorize access to personal information only for
                            those employees who require it to fulfill their job
                            responsibilities.
                          </p>
                          <p className="legal-text">
                            However, we cannot guarantee that loss, misuse,
                            unauthorized acquisition, or alteration of your data
                            will not occur. Please recognise that you play a
                            vital role in protecting your own personal
                            information. When registering with our Services, it
                            is important to choose a password of sufficient
                            length and complexity, to not reveal this password
                            to any third-parties, and to immediately notify us
                            if you become aware of any unauthorized access to or
                            use of your account.
                          </p>
                          <p className="legal-text">
                            Furthermore, we cannot ensure or warrant the
                            security or confidentiality of information you
                            transmit to us or receive from us by Internet or
                            wireless connection, including email, phone, or SMS,
                            since we have no way of protecting that information
                            once it leaves and until it reaches us. If you have
                            reason to believe that your data is no longer
                            secure, please contact us using the contact
                            information provided in the “Contact” section Above
                            or Below.
                          </p>
                        </div>
                      </section>
                      <section 
                       title="5. Children's Privacy"
                       id="childrens-privacy"
                      >
                        <div
                          width="default"
                          className="privacy-policy-text-container"
                        >
                          <h3 className="privacy-policy-subheading-text">
                            5. Children's privacy
                          </h3>
                          <p className="legal-text">
                            Our Services do not address anyone under the age of
                            18. We do not knowingly collect personal
                            identifiable information from children under 18. In
                            the case we discover that a child under 18 has
                            provided us with personal information, we
                            immediately delete this from our servers. If you are
                            a parent or guardian and you are aware that your
                            child has provided us with personal information,
                            please contact us so that we will be able to do
                            necessary actions.
                          </p>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </main>
            </div>
            <footer>
              <Footer />
            </footer>
          </div>
        );
    }
}

export default PrivacyPolicy;
