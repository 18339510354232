import React, { Component } from "react";
//import Popup from "reactjs-popup";
import Popup from "../../Common/Popup/Popup";

import MainBodyContainer from "../../Common/Body/MainBodyContainer/MainBodyContainer";
import Footer from "../../Footer/Footer";
import HamburgerMenu from "../../Navbar/HamburgerMenu/HamburgerMenu";
import Navbar from "../../Navbar/Navbar";

import "../Our-Team/Our-Team.css"


class OurTeam extends Component {
  employeeList = [
    {
      name: "Emi",
      title: "Assessor & Verifier",
      description: "",
      photo: "emigjoni.jpeg",
    },
    {
      name: "Arlidio",
      title: "IT & Business Development",
      description: "",
      photo: "empty.png",
    },
    {
      name: "Julia",
      title: "Vocational Trainer",
      description: "",
      photo: "empty.png",
    },
    {
      name: "Lina",
      title: "Admin & Project Coordinator",
      description: "",
      photo: "empty.png",
    },
  ];

  render() {
    return (
      <div>
        <Navbar />
        <div>
          <MainBodyContainer heading={"Our Team"}>
            <div className="subheading-description">
              <p>
                At Alisar, our team is composed of dedicated professionals
                committed to fostering positive change within our community. We
                have qualified and experienced careers advisers, trainers,
                assessors, and Internal Verifiers who are passionate about their
                work. We welcome partnerships that align with our vision of
                creating opportunities for women, young people, and other
                underrepresented groups, particularly within racially and
                ethnically diverse communities that face significant challenges.
                <br />
                <br />
                We champion equality, celebrate diversity, and empower women. Our workplace ethos promotes a
                non-judgmental and flexible environment, ensuring a balanced
                approach to our work. Our primary focus is on inspiring
                enthusiasm and dedication within our staff, enabling them to
                motivate and support others in reaching their goals. We strive
                to be role models in our community, actively promoting
                empowerment and collaboration among all members, regardless of
                their backgrounds.
              </p>
            </div>
            <div>
              <div className="row">
                {this.employeeList.map((employee) => {
                  return <Popup employee={employee}></Popup>;
                })}
              </div>
            </div>
          </MainBodyContainer>
        </div>
        <Footer />
      </div>
    );
  }
}

export default OurTeam;