import React, { Component } from "react";
import { Link } from "react-router-dom";
import { NavbarItems } from "../../Navbar/NavbarItems";
import Testimonials from "../Testimonials/Testimonials";
import "./Overview.css";

class Overview extends Component {
  render() {
    return (
      <div className="overview-container">
        <div className="overview-header">
          <h1>Alisar Prospects Training CIC</h1>
          <h3>
            Building blocks to your success{" "}
            <img src="https://img.icons8.com/ios-glyphs/80/000000/stairs-up.png" />
          </h3>
        </div>
        <div className="overview-content">
          <br />
          <h2>Welcome to Alisar Prospects Training</h2>
          <p className="subheading-text">
            At Alisar, we are passionate about empowering individuals in the
            community by providing the essential building blocks for career
            success. Our mission is to ensure that everyone has equal access to
            high-quality education and employment opportunities, regardless of
            their background.
            <br />
            <br />
            We specialise in vocational training that equips you with practical
            skills and qualifications to confidently navigate today’s job
            market. Our experienced team of careers advisers, trainers, and
            mentors brings a wealth of knowledge, delivering personalised
            support to community members from diverse backgrounds.
            <br />
            <br />
            Our programmes focus on enhancing your CV, mastering job search
            techniques, and acing interviews. We believe in nurturing your
            potential through one-on-one guidance and valuable work placements,
            helping you make progress in your career.
          </p>
          <br /> <br /> <br />
          <h3>Your Path to Success Begins Here</h3>
          <p>
            Join us at Alisar, and let’s work together to unlock your potential
            and build a brighter future. We are committed to fostering an
            inclusive environment where every community member can thrive and
            achieve their career goals.
          </p>
          <br /> <br /> <br />
          <h3>Reach Out for Support</h3>
          <p>
            Discover how our dedicated team can support you in taking the next
            step on your journey. Together, we can create a pathway to success!
          </p>
          <Link to="/contact" className="body-button">
            <div className="body-btn-circle"></div>
            <a href="/">Contact Us</a>
          </Link>
        </div>
        <div className="overview-subcontent">
          <div>
            <h3 className="subcontent-header">
              <span>Delivered By Alisar Prospects Training</span>
            </h3>
            <ul className="subcontent-list">
              <li>
                <a href="/service/london-community-outreach-project">
                  London Community Outreach Program
                </a>
              </li>
              <li>
                <a href="/course/customer-service-level-1-3">
                  Customer Service Level 1 — 3
                </a>
              </li>
              <li>
                <a href="/service/careers-advice">Careers Advice</a>
              </li>
              <li>
                <a href="/courses">Other Courses</a>
              </li>
              <li>
                <a href="/contact">Other Queries</a>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="subcontent-header testimonial-header">
              <span>Testimonials</span>
            </h3>
            <div className="testimonial-container">
              <Testimonials />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Overview;
