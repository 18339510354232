import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import "./Latest-News.css";

class LatestNews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      latestNews: [],
    };
  }

  componentDidMount = () => {
    const mediumURL =
      "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@alisarprospectstraining";

    const storedArticles = [];

    fetch(mediumURL).then((response) => {
      response.json().then((json) => {
        json.items.map((article) => {
          if (storedArticles.length < 3) {
            // Extract the first <img> tag's src from the content
            const parser = new DOMParser();
            const doc = parser.parseFromString(article.content, "text/html");
            const firstImg = doc.querySelector("img")
              ? doc.querySelector("img").src
              : "";

            storedArticles.push({
              title: article.title,
              author: article.author,
              imageHTML: firstImg,
              link: article.link,
            });
          }
        });
        this.setState({ latestNews: storedArticles });
      });
    });
  };

  render() {
    return (
      <div id="news-section">
        <div className="news-section-title">
          <Link to="/news" className="news-section-title-heading">
            Latest News
          </Link>
          <div className="news-infocards-container">
            {
              /* If no articles exist, show placeholder */
              this.state.latestNews.length === 0 && (
                <ul className="news-infocards">
                  <li>
                    <a className="news-infocard-item-container" href="/">
                      No Recent News.
                    </a>
                  </li>
                </ul>
              )
            }

            {this.state.latestNews.length > 0 && (
              <ul className="news-infocards">
                {this.state.latestNews.map((article) => {
                  return (
                    <li>
                      <a
                        className="news-infocard-item-container"
                        href={article.link}
                      >
                        <div className="news-infocard-item-image-container">
                          <img
                            alt={article.title + " image"}
                            src={article.imageHTML}
                            loading="lazy"
                          ></img>
                        </div>
                        <h2>{article.title}</h2>
                        <p>{article.author}</p>
                        <p>
                          Learn more
                          <svg
                            width="8"
                            height="11"
                            viewBox="0 0 8 11"
                            fill="none"
                            className="news-infocard-item-caret"
                          >
                            <path
                              d="M7.212 5.187L2.262.237l-1.77 1.77 3.18 3.18-3.18 3.18 1.77 1.77 4.95-4.95z"
                              fill="#0052ff"
                            ></path>
                          </svg>
                        </p>
                      </a>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default LatestNews;