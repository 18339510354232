import React, { Component } from "react";
import { Link, generatePath } from "react-router-dom";
import { generateSlug } from "../../utilities/helpers";
import { NavbarItems } from "../Navbar/NavbarItems";
import MainBodyContainer from "../Common/Body/MainBodyContainer/MainBodyContainer";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

import "./Services.css";

class Services extends Component {
  renderServiceCategory(category) {
    return (
      <div className="service-category" key={category.category}>
        <h2>{category.category}</h2>
        <div className="service-description">
          <p>{category.categoryDescription}</p>
        </div>
        {category.items.map((item, index) => (
          <Link
            to={generatePath(`/service/:title`, {
              title: generateSlug(item.title),
            })}
            key={index}
            className="service-item-link"
          >
            <div className="service-item">
              <div className="service-item-box">
                <h3>{item.title}</h3>
                <p>{item.description}</p>
                {item.className.includes("new-feature") && (
                  <span className="new-feature"></span>
                )}
              </div>
            </div>
          </Link>
        ))}
      </div>
    );
  }

  render() {
    return (
      <div>
        <Navbar />
        <MainBodyContainer heading={"Services"}>
          <div className="services-container">
            {NavbarItems.find(
              (item) => item.title === "Services"
            ).subnavitems.map((category) =>
              this.renderServiceCategory(category)
            )}
          </div>
        </MainBodyContainer>
        <Footer />
      </div>
    );
  }
}

export default Services;
