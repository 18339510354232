export const CourseData = [
  {
    title: "Advice & Guidance Level 1 - 3",
    description: "About This Course",
    imageURL:
      "https://images.unsplash.com/photo-1527689368864-3a821dbccc34?auto=format&fit=crop&q=80&w=3270&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    content: [
      {
        heading: "",
        text: [
          "This Level 2 course can help you brush up on your skills and make sure you're aware of the fundamental rules for successful information sharing.",
          "For many businesses, being able to offer clear and accurate information, advice or guidence (IAG) - and knowing what can be shared legally and professionally - is really important. This course covers communication techniques, interpersonal skills and group interactions, as well as how to store and retrieve information in a way that complies with the law.",
          "There are many organisations that provide information, advice or guidance services on issues such as: careers and employment, education and training, financial advice on issues such as debt, loans, mortgages and savings, childcare options, housing options, health care, disability, drug or alcohol addiction.",
        ],
      },
      {
        heading: "Eligibility Criteria",
        text: [
          "This course is government funded, you must meet the following criteria to be able to study for free:",
        ],
        list: [
          "You must currently live in England ",
          "You must have been resident in the UK/EU for 3+ years, for purposes other than study",
          "You must be over the age of 19",
          "You must not be on an apprenticeship",
        ],
      },
      {
        text: [
          "There is a £50 cancellation fee if you enrol and do not complete or withdraw from the course. This fee is to cover administration costs",
        ],
      },
      {
        heading: "Progression Opportunities",
        text: [
          "This course would be important for job roles such as:  School Business Manager (£25,000 to £55,000) Business Analyst (£30,000 to £70,000) Horticultural Manager (£18,000 to £40,000)",
        ],
      },
      {
        heading: "Duration",
        text: [
          "The duration of this course is seven weeks. We refer to our online courses as 'flexible learning' because they're designed to be completed around your work and home life commitments. All deadlines for your course will be shown in your online course platform once you are enrolled to help keep you on track.",
        ],
      },
    ],
  },
  {
    title: "Functional Skills (English & Maths)",
    description: "About This Course",
    imageURL:
      "https://images.unsplash.com/photo-1509228627152-72ae9ae6848d?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    content: [
      {
        heading: "",
        text: [
          "Level 2 Functional Skills is an alternative qualification to a GCSE English & Maths pass grade. Our online course uses real-life examples to improve your reading, writing, speaking & mathematics skills. If you find learning English easier with practical examples, this is the course for you.",
        ],
      },
      {
        heading: "What will I learn?",
        text: [
          "The functional skills qualification contains the following exams:",
        ],
        list: [
          "Reading: – This exam is usually 1 hour long and requires you to read a number of different styles of texts and then answer short questions on them, showing that you can analyse texts, comprehend the intended meaning, and identify language features used.",
          "Writing: This exam is usually 1 hour long and requires you to demonstrate your writing abilities in a context that is given in the paper. This may involve writing a piece of persuasive text, a complaint letter, or some other type of writing.",
          "Speaking listening and communication: This exam lasts around 1 and half hours and involves preparing a short presentation to share with other learners. You will present for a few minutes, listen to other presentations, then have a discussion. You are not marked on the quality of the content of your presentation, simply on your ability to communicate the content, and then how you respond and interact with other learners.",
          "Using numbers: This section covers the basics of whole numbers, decimals, negative numbers, fractions, and percentages, as well as learning how to convert between them and perform calculations involving them.",
          "Understanding measure, shape, and space: This section covers a larger ranger of topics, from simple 2D and 3D shapes, along with surface area, to using maps and interpreting scale drawings. This section will challenge your problem-solving ability, with these topics wrapped up in everyday scenarios for you to solve.",
          "Handling data:  This section covers the display and interpretation of data in many different formats. The fundamentals of the mean, mode, median, and range will be tested extensively as well as some basic probability graphs.",
        ],
      },
      {
        heading: "Duration",
        text: [
          "The duration of this course is seven weeks. We refer to our online courses as 'flexible learning' because they're designed to be completed around your work and home life commitments. All deadlines for your course will be shown in your online course platform once you are enrolled to help keep you on track.",
        ],
      },
    ],
  },
  {
    title: "Business Administration Level 1 - 2",
    description: "About This Course",
    imageURL:
      "https://images.unsplash.com/photo-1554224155-6726b3ff858f?q=80&w=2811&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    content: [
      {
        heading: "",
        text: [
          "Having more than 5.5 million private sector businesses in the UK highlights the essential need for administrative skills to thrive in a competitive setting. Whether you're refining your current skills or acquiring new ones, this course equips you with the knowledge necessary to deliver top-notch support to your workplace.",
        ],
      },
      {
        heading: "What will I learn?",
        text: ["This course is made up of three units:"],
        list: [
          "Develop understanding of basic administration concepts and practices ",
          "Review essential knowledge for daily and more complex administration tasks",
          "Learn business and administration national occupational standards",
        ],
      },
      {
        text: [
          "There is a £50 cancellation fee if you enrol and do not complete or withdraw from the course. This fee is to cover administration costs",
        ],
      },
      {
        heading: "Progression Opportunities",
        text: [
          "This course would be important for job roles such as: School Business Manager (£25,000 to £55,000) Business Project Manager (£22,000 to £70,000) Sales Administrator (£17,000 to £28,000)",
        ],
      },
      {
        heading: "Duration",
        text: [
          "The duration of this course is seven weeks. We refer to our online courses as 'flexible learning' because they're designed to be completed around your work and home life commitments. All deadlines for your course will be shown in your online course platform once you are enrolled to help keep you on track.",
        ],
      },
    ],
  },
  {
    title: "Customer Service Level 1 - 3",
    description: "About This Course",
    imageURL:
      "https://images.unsplash.com/photo-1556742049-0cfed4f6a45d?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    content: [
      {
        heading: "",
        text: [
          "Providing exceptional customer service is paramount to the success of any business. If you work in customer service, this Level 2 customer service course can improve your skills and ensure that every customer has a positive experience.",
          "This course will equip you with the knowledge to meet customers' needs and offer outstanding service, while also teaching you how to leverage feedback to enhance your performance.",
        ],
      },
      {
        heading: "What will I learn?",
        text: ["This course is made up of four units:"],
        list: [
          "Learn to deliver excellent customer service ",
          "Increase awareness of understanding and meeting customers’ needs",
          "Giving a great customer experience",
          "Learn from feedback and promote products and services",
        ],
      },
      {
        text: [
          "There is a £50 cancellation fee if you enrol and do not complete or withdraw from the course. This fee is to cover administration costs",
        ],
      },
      {
        heading: "Progression Opportunities",
        text: [
          "This course would be important for job roles such as: Financial Services Customer Adviser (£14,000 to £60,000) Resort Representative (Variable) Customer Service Assistant (£16,000 to £28,000)",
        ],
      },
      {
        heading: "Duration",
        text: [
          "The duration of this course is seven weeks. We refer to our online courses as 'flexible learning' because they're designed to be completed around your work and home life commitments. All deadlines for your course will be shown in your online course platform once you are enrolled to help keep you on track.",
        ],
      },
    ],
  },
  {
    title: "Health & Social Care Level 1 - 3",
    description: "About This Course",
    imageURL:
      "https://images.unsplash.com/photo-1587556930799-8dca6fad6d41?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    content: [
      {
        heading: "",
        text: [
          "Health and Social care practitioners play a vital role in supporting those with specific health needs. As the need for social care continues to rise, care workers must have up-to-date support and guidance that allows them to provide the best care possible for the people they work with. Take your first steps into your new career with our Health and Social Care pathway, covering topics such as personal care needs and general client care.",
        ],
      },
      {
        heading: "What will I learn?",
        text: [
          "Our Health and Social Care course covers the following units: ",
        ],
        list: [
          "Introduction to the Adult Care sector ",
          "Values and Principles of Adult Care",
          "Skills and Attitudes needed to work in Social Care",
          "Communication Skills",
          "Roles and Responsibilities of an Adult Social Care worker",
          "Learn about person-centred care and safe working practices",
          "Duty of Care and Safeguarding",
          "Improve understanding of Equality and Diversity, Safeguarding and Protection",
        ],
      },
      {
        text: [
          "There is a £50 cancellation fee if you enrol and do not complete or withdraw from the course. This fee is to cover administration costs",
        ],
      },
      {
        heading: "Duration",
        text: [
          "The duration of this course is seven weeks. We refer to our online courses as 'flexible learning' because they're designed to be completed around your work and home life commitments. All deadlines for your course will be shown in your online course platform once you are enrolled to help keep you on track.",
        ],
      },
    ],
  },
  {
    title: "Health & Safety in the Workplace Level 1 - 3",
    description: "About This Course",
    imageURL:
      "https://images.unsplash.com/photo-1547447175-a68d11e30d6b?q=80&w=2834&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    content: [
      {
        heading: "",
        text: [
          "This course is designed specifically for small and medium-size enterprises (SMEs) that need an understanding of their health and safety requirements. This two-day course provides a thorough grounding in the essentials of health and safety in the workplace.",
        ],
      },
      {
        heading: "What will I learn?",
        text: [
          "The course will help you to identify the legislation that’s applicable to your business and the steps you need to take to meet your legal requirements.",
        ],
        list: [
          "Health and safety legislation",
          "How to stay informed",
          "Introduction to the language of health and safety",
          "Overview of how to comply with health and safety requirements",
          "Overview of risk assessments and basic procedures",
        ],
      },
      {
        text: [
          "There is a £50 cancellation fee if you enrol and do not complete or withdraw from the course. This fee is to cover administration costs",
        ],
      },
      {
        heading: "Progression Opportunities",
        text: [
          "The course is designed for managers, supervisors, business owners, and anyone responsible for health and safety for small and medium-size businesses.",
        ],
      },
      {
        heading: "Duration",
        text: ["The duration of this course is two days"],
      },
    ],
  },
  {
    title: "Mental Health Awareness & Wellbeing Level 1 - 2",
    description: "About This Course",
    imageURL:
      "https://images.unsplash.com/photo-1578909196400-59f8f8156a05?q=80&w=2835&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    content: [
      {
        heading: "",
        text: [
          "Recent reports suggest that over 12 million working days were lost due to work-related stress, depression or anxiety in just one year. It’s important that employers are transparent and open about mental health issues.",
          "Increasing the understanding of mental health among staff at all levels builds a culture of care and support. In this course, you will learn how to support individuals with their mental health, as well as what a mentally healthy environment is like.",
        ],
      },
      {
        heading: "What will I learn?",
        text: [
          "Our Mental Health First Aid and Advocacy in the Workplace is designed to give you the foundation knowledge to support colleagues with their mental health. You will study three units: ",
        ],
        list: [
          "Exploring mental health ",
          "Understand how to support individuals with mental ill health",
          "Understand a mentally healthy environment",
        ],
      },
      {
        text: [
          "There is a £50 cancellation fee if you enrol and do not complete or withdraw from the course. This fee is to cover administration costs",
        ],
      },
      {
        heading: "Progression Opportunities",
        text: [
          "This course would be important for job roles such as: Mental Health Nurser (£25,654 to £45,838) Health Promotion Specialist (£21,000 to £40,000) Human Resources Officer (£15,000 to £50,000)",
        ],
      },
      {
        heading: "Duration",
        text: [
          "The duration of this course is seven weeks. We refer to our online courses as 'flexible learning' because they're designed to be completed around your work and home life commitments. All deadlines for your course will be shown in your online course platform once you are enrolled to help keep you on track.",
        ],
      },
    ],
  },
  {
    title: "Safeguarding & Protecting Children & Young people Level 2",
    description: "About This Course",
    imageURL:
      "https://images.unsplash.com/photo-1527689368864-3a821dbccc34?auto=format&fit=crop&q=80&w=3270&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    content: [
      {
        heading: "",
        text: [
          "in 2019, a total of 5,738 individuals were referred to Prevent programme, with the education sector accounting for 33% of this number. Safeguarding is an essential part of any role that involves working with children, young people, or vulnerable adults.",
          "This nationally recognised Level 2 qualification delves into various aspects of safeguarding, including identifying signs of abuse, understanding legal and ethical responsibilities, implementing effective reporting procedures, and ensuring the well-being of children and young adults.",
        ],
      },
      {
        heading: "What will I learn?",
        text: ["This course is made up of 3 units:"],
        list: [
          "Understanding the Prevent duty",
          "Understanding safeguarding",
          "Understanding online safety",
        ],
      },
      {
        text: [
          "There is a £50 cancellation fee if you enrol and do not complete or withdraw from the course. This fee is to cover administration costs",
        ],
      },
      {
        heading: "Progression Opportunities",
        text: [
          "This course would be important for job roles such as: Local Government Administrative Assistant (£17,500 to £24,500) Higher Education Lecturer (£33,000 to £54,000) Social Worker (£24,000 to £40,000)",
        ],
      },
      {
        heading: "Duration",
        text: [
          "The duration of this course is seven weeks. We refer to our online courses as 'flexible learning' because they're designed to be completed around your work and home life commitments. All deadlines for your course will be shown in your online course platform once you are enrolled to help keep you on track.",
        ],
      },
    ],
  },
  {
    title: "Equality & Diversity Level 2",
    description: "About This Course",
    imageURL:
      "https://images.unsplash.com/photo-1654045851484-d9aea271acda?q=80&w=2944&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    content: [
      {
        heading: "",
        text: [
          "If you want to learn about equality and diversity in different contexts, this course will give you a great understanding of the different issues affecting equality and diversity in society today. You will gain an insight into the wide-ranging legislation that's needed to create a workplace centred on fairness, dignity and respect.",
        ],
      },
      {
        heading: "What will I learn?",
        text: [
          "You will gain an insight into the wide-ranging legislation that's needed to create a workplace centred on fairness, dignity and respect. This course is made up of two units:",
        ],
        list: [
          "The importance of equality and diversity within different contexts.",
          "The different issues affecting equality and diversity in society.",
        ],
      },
      {
        text: [
          "There is a £50 cancellation fee if you enrol and do not complete or withdraw from the course. This fee is to cover administration costs",
        ],
      },
      {
        heading: "Progression Opportunities",
        text: [
          "This course would be important for job roles such as: Office Manager (£18,000 to £38,000) Travel Agency Manager (£20,000 to £40,000) Human Resources Officer (£15,000 to £50,000)",
        ],
      },
      {
        heading: "Duration",
        text: [
          "The duration of this course is seven weeks. We refer to our online courses as 'flexible learning' because they're designed to be completed around your work and home life commitments. All deadlines for your course will be shown in your online course platform once you are enrolled to help keep you on track.",
        ],
      },
    ],
  },
  {
    title: "Food & Safety Level 1 - 3",
    description: "About This Course",
    imageURL:
      "https://images.unsplash.com/photo-1614706250649-b5ccfc837688?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    content: [
      {
        heading: "",
        text: [
          "If you want to learn about equality and diversity in different contexts, this course will give you a great understanding of the different issues affecting equality and diversity in society today. You will gain an insight into the wide-ranging legislation that's needed to create a workplace centred on fairness, dignity and respect.",
        ],
      },
      {
        heading: "What will I learn?",
        text: [
          "The training modules included in this course cover the key syllabus of Level 1-3 Food Safety qualifications, placed in three categories. These categories/modules include:",
        ],
        list: [
          "Introduction to food safety",
          "Microbiology",
          "Contamination hazards and control",
          "Food poisoning",
          "Personal hygiene",
          "Design, construction and use of food premises and equipment",
          "Cleaning and disinfection",
          "Pest control",
          "Food Safety legislation and enforcement",
        ],
      },
      {
        text: [
          "There is a £50 cancellation fee if you enrol and do not complete or withdraw from the course. This fee is to cover administration costs",
        ],
      },
      {
        heading: "Duration",
        text: [
          "The duration of this course is seven weeks. We refer to our online courses as 'flexible learning' because they're designed to be completed around your work and home life commitments. All deadlines for your course will be shown in your online course platform once you are enrolled to help keep you on track.",
        ],
      },
    ],
  },
  {
    title: "Paediatric First Aid",
    description: "About This Course",
    imageURL:
      "https://images.unsplash.com/photo-1578496781379-7dcfb995293d?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    content: [
      {
        heading: "",
        text: [
          "This course is comprehensive, interactive and fun. The course focuses on emergency scenarios that are faced by nannies, childminders, nanny/housekeepers and people who work with young children.",
          "This course meets the First Aid requirements of OFSTED for the voluntary register.",
        ],
      },
      {
        heading: "What will I learn?",
        text: ["The training modules included in this course are: "],
        list: [
          "Role and responsibilities of paediatric first aider",
          "Incident and casualty assessment",
          "CPR",
          "Unconscious casualty",
          "Recovery position",
          "Management of a seizure",
          "Choking",
          "Anaphylaxis",
          "Shock",
          "External bleeding",
        ],
      },
    ],
  },
  {
    title: "British Values Entry Level 3",
    description: "About This Course",
    imageURL:
      "https://images.unsplash.com/photo-1532264251691-2ad92a2ec88f?q=80&w=2942&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    content: [
      {
        heading: "",
        text: [
          "We see British Values as underpinning what it is to be a citizen in a modern and diverse Britain. They allow us to create environments free from discrimination, intolerance and hate. They help us to challenge prejudice and stereotyping, whilst strengthening relationships within the community.",
        ],
      },
      {
        heading: "What will I learn?",
        text: ["British Values are defined as the following: "],
        list: [
          "Democracy - A culture built upon freedom and equality, where everyone is aware of their rights and responsibilities.",
          "The rule of law - The need for rules to make a happy, safe and secure environment to live and work.",
          "Individual liberty - Protection of your rights and the rights of others around you.",
          "Mutual respect & tolerance of different faiths and beliefs - Understanding that we all don’t share the same beliefs and values. Respecting those values, ideas and beliefs of others whilst not imposing our own onto them.",
        ],
      },
    ],
  }
];