import React, { Component } from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import ReactTooltip from "react-tooltip";
import "animate.css";

import Navbar from "../../Navbar/Navbar";
import MainBodyContainer from "../../Common/Body/MainBodyContainer/MainBodyContainer";
import Footer from "../../Footer/Footer";

import "../Mission/Mission.css";

class Mission extends Component {
  companyFigures = [
    // {
    //   figure: "1000",
    //   description: "Students Taught",
    // },
    // {
    //   figure: "20",
    //   description: "Courses Delivered",
    // },
    // {
    //   figure: "100",
    //   description: "Something Something",
    // },
    // {
    //   figure: "100",
    //   description: "Something Something",
    // },
    // {
    //   figure: "100",
    //   description: "Something Something",
    // },
    // {
    //   figure: "100",
    //   description: "Something Something",
    // },
    // {
    //   figure: "100",
    //   description: "Something Something",
    // },
    // {
    //   figure: "100",
    //   description: "Something Something",
    // },
  ];

  companyValues = [
    {
      phrase: "Fairness & Access",
      description:
        "We ensure all participants, regardless of background or circumstances, have equitable access to opportunities that support their growth and success.",
    },
    {
      phrase: "Enablement",
      description:
        "We help individuals unlock their potential, guiding them to take control of their skills, expertise, and career journeys.",
    },
    {
      phrase: "Innovation",
      description:
        "We strive to deeply understand the needs of our community, delivering creative solutions and services that align with their goals and aspirations.",
    },
    {
      phrase: "Inclusion",
      description:
        "We embrace and honour the diversity of our community, creating a supportive and inclusive space where everyone is encouraged to contribute and develop confidently.",
    },
  ];

  render() {
    return (
      <div>
        <Navbar />
        <div>
          <MainBodyContainer heading={"Our Mission"}>
            <div className="two-column-container">
              <div className="left-column">
                <h1 className="animate__animated animate__fadeInUp">
                  WE GIVE YOU THE
                </h1>
                <h1 className="animate__animated animate__fadeInUp stylised-text">
                  SKILLS & CONFIDENCE,
                </h1>
                <h1 className="animate__animated animate__fadeInUp">
                  FOR THE FUTURE YOU{" "}
                  <span className="stylised-text">WANT.</span>
                </h1>
              </div>
              <div className="right-column">
                <div>
                  <img src="https://images.unsplash.com/photo-1538688423619-a81d3f23454b?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"></img>
                </div>
              </div>
            </div>
            <div className="body-section-container">
              <div className="body-row">
                <div className="body-column">
                  <h2 className="section-header">Our Values</h2>
                </div>
                <div className="body-column">
                  <div className="values-content">
                    <div className="value-item">
                      <h3 className="highlight">Innovation</h3>
                      <p>
                        We strive to deeply understand the needs of our
                        community, delivering creative solutions and services
                        that align with their goals and aspirations.
                      </p>
                    </div>
                    <div className="value-item">
                      <h3 className="highlight">Enablement</h3>
                      <p>
                        We help individuals unlock their potential, guiding them
                        to take control of their skills, expertise, and career
                        journeys.
                      </p>
                    </div>
                    <div className="value-item">
                      <h3 className="highlight">Fairness & Access</h3>
                      <p>
                        We ensure all participants, regardless of background or
                        circumstances, have equitable access to opportunities
                        that support their growth and success.
                      </p>
                    </div>
                    <div className="value-item">
                      <h3 className="highlight">Inclusion</h3>
                      <p>
                        We embrace and honour the diversity of our community,
                        creating a supportive and inclusive space where everyone
                        is encouraged to contribute and develop confidently.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="values-container">
                {
                  // I AM COMMENTING THIS OUT BECAUSE WE'RE TRYING SOMETING ELSE
                  /* {this.companyValues.map((value) => {
                  return (
                    <div data-tip={value.description} className="values-item">
                      <h2>{value.phrase.substring(0, 1)}</h2>
                      <p>{value.phrase}</p>
                      <ReactTooltip
                        className="tooltip"
                        place="right"
                        type="light"
                        effect="solid"
                      />
                    </div>
                  );
                })} */
                }
              </div>

              <div>
                <div className="objectives">
                  <h2 className="section-header">Our Objectives</h2>
                  <ul>
                    <li>
                      <strong>Personalised Support</strong> – Offering equal
                      opportunities with tailored learning plans and impartial
                      advice to help individuals reach their goals.
                    </li>
                    <li>
                      <strong>Engaging Learning Environment</strong> – Creating
                      stimulating and enjoyable learning experiences that
                      empower participants to unlock their full potential.
                    </li>
                    <li>
                      <strong>Workforce Development</strong> – Delivering
                      nationally recognised training that equips businesses and
                      employees with essential skills for sustainable success.
                    </li>
                    <li>
                      <strong>Flexibility & Quality</strong> – Providing
                      flexible, high-quality services that meet the needs of
                      both employers and individuals.
                    </li>
                    <li>
                      <strong>Continuous Improvement</strong> – Regularly
                      refining our services to ensure excellence.
                    </li>
                    <li>
                      <strong>Community & Industry Link</strong> – Bridging the
                      gap between businesses and the local community to enhance
                      employability and economic growth.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* <div className="">
              <div className="apt-numbers">
                <div className="stats-title stats-title-top">
                  <h2>The Numbers Game</h2>
                </div>
                <div className="body-row stats-row">
                  {this.companyFigures.map((entry) => {
                    return (
                      <div className="stats-item">
                        <h2 className="stats-number">
                          <VisibilitySensor
                            className="tooltip"
                            partialVisibility
                            offset={{ bottom: 20 }}
                          >
                            {({ isVisible }) => (
                              <div style={{ height: 70 }}>
                                {isVisible ? (
                                  <CountUp end={entry.figure} />
                                ) : null}
                              </div>
                            )}
                          </VisibilitySensor>
                        </h2>
                        <h6 className="stats-description">
                          {entry.description}
                        </h6>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div> */}
          </MainBodyContainer>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Mission;