import React from "react";
import PropTypes from 'prop-types';
import "./MainBodyContainer.css"


export default function MainBodyContainer({ children, heading }) {
    return (
      <main className="main-body">
        {heading !== undefined && <h1 className="main-body-heading">{heading}</h1>}
        {children}
      </main>
    );
    
}

MainBodyContainer.propTypes = {
    children : PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element), 
    PropTypes.element.isRequired
  ]),
    heading : PropTypes.string
}
