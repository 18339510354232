import React, { Component } from "react";
import "./Home.css";
import Navbar from "../Navbar/Navbar";
import Overview from "./Overview/Overview";
import LatestNews from "./Latest-News/Latest-News";
import Slideshow from "./Slideshow/Slideshow";
import Footer from "../Footer/Footer";
import MainBodyContainer from "../Common/Body/MainBodyContainer/MainBodyContainer";

class Home extends Component {

    render() {
        return (
          <div>
            <Navbar />
            <div className="index-container">
              <div>
                <Overview />
                <MainBodyContainer>
                  <LatestNews id="news-section-container" />
                </MainBodyContainer>
                <MainBodyContainer>
                  <Slideshow />
                </MainBodyContainer>
              </div>
            </div>
            <footer>
              <Footer id="footer-section-container" />
            </footer>
          </div>
        );
    }
}

export default Home;