import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Footer.css"

class Footer extends Component {

    render() {
        return (
          <div id="footer-section">
            <div className="footer-wrapper">
              <section className="footer-company-section">
                <div className="footer-company-section-wrapper">
                  <h1>APT</h1>
                  <div className="footer-company-section-company-details">
                    <div className="copyright-details">
                      © 2022 Alisar Prospects Training
                    </div>
                    <div className="social-buttons-container">
                      <a href="tel:02030266258" className="social-button">
                        <i className="las la-phone-volume"></i>
                      </a>
                      <a
                        href="mailto:info@alisarprospects.training"
                        className="social-button"
                      >
                        <i className="las la-at"></i>
                      </a>
                      <a
                        href="https://www.facebook.com/AlisarProspectsTrainingCIC/"
                        className="social-button"
                      >
                        <i className="lab la-facebook"></i>
                      </a>
                      <a
                        href="https://www.instagram.com/apt_cic/?hl=en-gb"
                        className="social-button"
                      >
                        <i className="lab la-instagram"></i>
                      </a>
                      <a
                        href="https://goo.gl/maps/xUqC1tn4ZAjJreax7"
                        className="social-button"
                      >
                        <i className="las la-map"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </section>
              <div className="footer-links-container">
                <div>
                  <section className="footer-links-section-container">
                    <h2>Company</h2>
                    <ul className="footer-links">
                      <li>
                        <a href="/">About</a>
                      </li>
                      <li>
                        <a href="/">Careers</a>
                      </li>
                      <li>
                        <Link to="/privacy-policy">Legal & privacy</Link>
                      </li>
                      <li>
                        <Link to="/cookie-policy">Cookie Policy</Link>
                      </li>
                    </ul>
                  </section>
                </div>
                <div className="footer-links-section-container">
                  <h2>Learn</h2>
                  <ul className="footer-links">
                    <li>
                      <a href="/">Learning Portal</a>
                    </li>
                  </ul>
                </div>
                <div className="footer-links-section-container">
                  <h2>Support</h2>
                  <ul className="footer-links">
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        );
    }
}

export default Footer;