import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import MainBodyContainer from "../Common/Body/MainBodyContainer/MainBodyContainer";
import Footer from "../Footer/Footer";
import "./Contact.css";

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {},
      errors: {},
      isSubmitted: false
    };
  }

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //Input
    if (!fields["issue"]) {
      formIsValid = false;
      errors["issue"] = "Cannot be empty ";
    }

    //Name
    if (typeof fields["name"] !== "undefined") {
      if (!fields["name"].match(/^[a-zA-Z ]{2,30}$/)) {
        formIsValid = false;
        errors["name"] = "Only letters can be included in your name";
      }
    }

    //Details
    if (!fields["details"]) {
      formIsValid = false;
      errors["details"] = "Cannot be empty";
    }

    //Email
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Cannot be empty " + fields["email"];
    }

    if (typeof fields["email"] !== "undefined") {
      let lastAtPos = fields["email"].lastIndexOf("@");
      let lastDotPos = fields["email"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Email is not valid";
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  formSubmit(e) {
    e.preventDefault();

    if (this.handleValidation()) {

      this.setState({ isSubmitted: true });

      var formData = {
        "contact-name": this.state.fields["name"],
        "contact-email": this.state.fields["email"],
        "issue-description": this.state.fields["details"],
        "issue-category": this.state.fields["issue"],
      };

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      };

      fetch("http://localhost:3001/contact-form-submission", requestOptions)
      .then(function (response) {
        if (response.status === 200){
          console.log("hello. ");
          return;
        }
        else {
         // form error handling here 
        }
      });

      console.log(this.state.isSubmitted);

      return true;

    } else { return false; }
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  render() {
    return (
      <div>
        <Navbar />
        <div>
          <div className="contact-heading-wrapper">
            <header className="contact-heading-container">
              <h1>Contact Us</h1>
              <h3>we're here to support you.</h3>
            </header>
          </div>
          <div className="index-container">
            <MainBodyContainer>
              <div className="two-column-layout">
                <section className="main-col">
                  <section className="contact-us-container">
                    <section className="contact-us-text">
                      <h1>APT Help Center.</h1>
                      <p>
                        Explore the{" "}
                        <Link id="help-center-link" to="">
                          Help Center
                        </Link>{" "}
                        for quick answers to common questions.
                      </p>
                      <p>
                        If you prefer to chat with a member of our support team,
                        please reach out by phone, mail, or fill out the form
                        below.
                      </p>
                    </section>
                    <section className="contact-form-container">
                      <h2>Contact form</h2>
                      <section className="contact-form-section">
                        <form
                          data-netlify="true"
                          onSubmit={this.formSubmit.bind(this)}
                          id="contact-us-form"
                        >
                          <section className="contact-form-dropdown-container">
                            <section className="contact-form-dropdown">
                              <div>
                                <label>
                                  Why are you reaching out to us?
                                  <span>*</span>
                                  <span className="error">
                                    {this.state.errors["issue"]}
                                  </span>
                                </label>
                              </div>
                              <select
                                ref="issue"
                                onChange={this.handleChange.bind(this, "issue")}
                                value={this.state.fields["issue"]}
                                required
                              >
                                <option selected disabled hidden>
                                  Select a reason
                                </option>
                                <option>Training Course</option>
                                <option>Careers Advice</option>
                                <option>Employability</option>
                                <option>Volunteering</option>
                                <option>Other</option>
                              </select>
                            </section>
                            <section className="contact-form-dropdown">
                              <div>
                                <label>
                                  What is your full name?
                                  <span className="error">
                                    {this.state.errors["name"]}
                                  </span>
                                </label>
                              </div>
                              <input
                                ref="name"
                                type="text"
                                onChange={this.handleChange.bind(this, "name")}
                                value={this.state.fields["name"]}
                              ></input>
                            </section>
                            <section className="contact-form-dropdown">
                              <div>
                                <label>
                                  Can you provide further details?
                                  <span>*</span>
                                  <span className="error">
                                    {this.state.errors["details"]}
                                  </span>
                                </label>
                              </div>
                              <textarea
                                ref="details"
                                required
                                onChange={this.handleChange.bind(
                                  this,
                                  "details"
                                )}
                                value={this.state.fields["details"]}
                              ></textarea>
                            </section>
                            <section className="contact-form-dropdown">
                              <div>
                                <label>
                                  What is your email address?
                                  <span>*</span>
                                  <span className="error">
                                    {this.state.errors["email"]}
                                  </span>
                                </label>
                              </div>
                              <input
                                ref="email"
                                required
                                type="email"
                                onChange={this.handleChange.bind(this, "email")}
                                value={this.state.fields["email"]}
                              ></input>
                            </section>
                            <section className="contact-form-dropdown">
                              <button
                                disabled={this.state.isSubmitted}
                                type="submit"
                              >
                                {this.state.isSubmitted
                                  ? "Contact Form Sent"
                                  : "Send Contact Form"}
                              </button>
                            </section>
                          </section>
                        </form>
                        {this.state.isSubmitted && (
                          <p>
                            Thank you for your submission. We aim to produce a
                            response in 1-5 days.
                          </p>
                        )}
                      </section>
                    </section>
                    <section>
                      <h2>Email us</h2>
                      <a
                        className="email-contact"
                        href="mailto:info@alisarprospects.training"
                      >
                        info@alisarprospects.training
                      </a>
                    </section>
                  </section>
                </section>
                <section className="side-col">
                  <h2 className="mobile-viewport-only">Call us</h2>
                  <section className="contact-us-sidebar">
                    <section className="phone-support">
                      <div className="phone-support-container">
                        <div className="phone-support-text">
                          <div>
                            <h3>Give us a call</h3>
                            <p>
                              Speak directly with a member of our team to get
                              answers to your questions.
                            </p>
                          </div>
                          <figure>
                            <i class="las la-mobile"></i>
                          </figure>
                        </div>

                        <ul className="phone-support-list">
                          <li className="phone-support-list-item">
                            <div className="phone-support-list-item-text">
                              <span>UK</span>
                              <a href="tel:+442030266258">+4420 3026 6258</a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </section>
                  </section>
                </section>
              </div>
            </MainBodyContainer>
          </div>
        </div>
        <footer>
          <Footer />
        </footer>
      </div>
    );
  }
}

export default Contact;