import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../Navbar/Navbar";
import Footer from "../../Footer/Footer";
import "./Cookie-Policy.css";

class CookiePolicy extends Component {
  render() {
    return (
      <div>
        <Navbar />
        <div>
          <main className="main-body">
            <h1 className="main-body-heading">Legal</h1>
            <div>
              <h2>None so far!</h2>
            </div>
          </main>
        </div>
        <footer>
          <Footer />
        </footer>
      </div>
    );
  }
}

export default CookiePolicy;