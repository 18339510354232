import React, { useRef, useState, Component } from "react";


import "./Slideshow.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Partners } from "../Misc/Partners";


function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={className + " prevArrow"}
      style={{ display: "block" }}
      onClick={onClick}
    ></div>
  );
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <i class="las la-angle-right"></i>
    </div>
  );
}


export default function Slideshow() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
    };
    return (
      <Slider {...settings}>
        {Partners.map((item, index) => {
          return (
            <div className="slideItem">
              <div className="partner-logo-container">
                <a href={item.externalURL}>
                  <img
                    className="partner-logo"
                    src={require(`../../../images/${item.logoURL}`)}
                    alt={item.name + " logo"}
                  />
                </a>
              </div>
            </div>
          );
        })}
      </Slider>
    );
}