import React, { Component, useState, useEffect, useRef } from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import "./News.css";
import MainBodyContainer from "../Common/Body/MainBodyContainer/MainBodyContainer";

function FadeInSection(props) {
  const [isVisible, setVisible] = useState(true);
  const domRef = useRef();
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
    return () => observer.unobserve(domRef.current);
  }, []);
  
  return (
    <a
      href={props.href}
      className={`news-section-item fade-in-section ${
        isVisible ? "is-visible" : ""
      }`}
      ref={domRef}
    >
      {props.children}
    </a>
  );
}

class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newsArticles: [
        {
          year: 2022,
          articles: [],
        }
      ],
    };
  }

  componentDidMount = () => {
    const mediumURL =
      "https://api.rss2json.com/v1/api.json?rss_url=http://medium.com/feed/@alisarprospectstraining";

    const storedArticles = [
      {
        year: 2022,
        articles: [],
      },
    ];
    
    fetch(mediumURL).then((response) => {
      response.json().then((json) => {
        json.items.map((article) => {
          let articleYear = new Date(article.pubDate.substring(0, 10));
          let foundYear = false;

          // if article year exists
        for (var i in storedArticles) {
          if (storedArticles[i].year === articleYear.getFullYear()) {
            storedArticles[i].articles.push(article);
            foundYear = true;
          }
        }

        // if article year does NOT exist
        if (foundYear === false) {
          storedArticles.push({
            year: articleYear.getFullYear(),
            articles: [article],
          });
        }

        // reset flag for next article
        foundYear = false;
        });
        
        this.setState({newsArticles : storedArticles})
      })
    }
    );
  };

  render() {
    return (
      <div>
        <Navbar />
        <div>
          <MainBodyContainer heading={"News"}>
              {this.state.newsArticles.map((item, index) => {
                return (
                  <div>
                    <div className="news-year-header">
                      <h1>{item.year}</h1>
                      <hr />
                    </div>
                    {item.articles.length > 0 && (
                      <div className="news-section-container">
                        {item.articles.map((article) => {
                          return (
                            <FadeInSection href={article.link}>
                              <span>
                                <img
                                  className="news-section-item-picture"
                                  src={article.thumbnail}
                                  alt={article.title}
                                ></img>
                                <span className="news-section-item-text">
                                  <h1>{article.title}</h1>
                                  <h3>
                                    <span>
                                      {new Date(article.pubDate).toDateString()}{" "}
                                      | {article.categories[0]}
                                    </span>
                                  </h3>
                                </span>
                              </span>
                            </FadeInSection>
                          );
                        })}
                      </div>
                    )}
                    {item.articles.length === 0 && (
                      <div className="news-section-container">
                        <h1>No Available News.</h1>
                      </div>
                    )}
                  </div>
                );
              })}
            </MainBodyContainer>
        </div>
        <footer>
          <Footer />
        </footer>
      </div>
    );
  }
}

export default News;
